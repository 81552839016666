export const RESET_STORE_PRODUCT = "RESET_STORE_PRODUCT";

export const ALL_NOTIFICATION_LIST_REQUEST = "ALL_NOTIFICATION_LIST_REQUEST";
export const ALL_NOTIFICATION_LIST_SUCCESS = "ALL_NOTIFICATION_LIST_SUCCESS";
export const ALL_NOTIFICATION_LIST_ERROR = "ALL_NOTIFICATION_LIST_ERROR";

export const NOTIFICATION_DETAILS_REQUEST = "NOTIFICATION_DETAILS_REQUEST";
export const NOTIFICATION_DETAILS_SUCCESS = "NOTIFICATION_DETAILS_SUCCESS";
export const NOTIFICATION_DETAILS_ERROR = "NOTIFICATION_DETAILS_ERROR";


export const CERETE_NOTIFICATION_REQUEST = "CERETE_NOTIFICATION_REQUEST";
export const CERETE_NOTIFICATION_SUCCESS = "CERETE_NOTIFICATION_SUCCESS";
export const CERETE_NOTIFICATION_ERROR = "CERETE_NOTIFICATION_ERROR";

export const UPDATE_NOTIFICATION_REQUEST = "UPDATE_NOTIFICATION_REQUEST";
export const UPDATE_NOTIFICATION_SUCCESS = "UPDATE_NOTIFICATION_SUCCESS";
export const UPDATE_NOTIFICATION_ERROR = "UPDATE_NOTIFICATION_ERROR";

export const NOTIFICATION_DELETE_REQUEST = "NOTIFICATION_DELETE_REQUEST";
export const NOTIFICATION_DELETE_SUCCESS = "NOTIFICATION_DELETE_SUCCESS";
export const NOTIFICATION_DELETE_ERROR = "NOTIFICATION_DELETE_ERROR";