import * as types from "./actionTypes";

const initialState = {
  loading: false,
  errorMessage: "",
  isError: false,
  isRefrash: false,
  adminRoles: null,
  adminPermissions: null
 
};

const SubjectReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_STORE_PRODUCT:
      return {
        ...state,
      };

    //end
    case types.GET_ADMIN_ROLES_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.GET_ADMIN_ROLES_SUCCESS:
      return {
        ...state,
        loading: false,
        isError: false,
        isRefrash: false,
        adminRoles: action.payload,
      };

    case types.GET_ADMIN_ROLES_ERROR:
      return {
        ...state,
        loading: false,
        isRefrash: false,
        adminRoles: null,
        isError: true,
      };

    case types.GET_ADMIN_PERMISSIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.GET_ADMIN_PERMISSIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        isError: false,
        isRefrash: false,
        adminPermissions: action.payload,
      };

    case types.GET_ADMIN_PERMISSIONS_ERROR:
      return {
        ...state,
        loading: false,
        isRefrash: false,
        adminPermissions: null,
        isError: true,
      };
    

    default:
      return state;
  }
};

export default SubjectReducer;
