import toast from "react-hot-toast";
import * as types from "./actionTypes";
import ApiList from "../../webservice/ApiList";
import { apiLoadingStart, apiLoadingStop } from "../global";
import { AlertHelper } from "../../Constants/AlertHelper";
export const resetStore = () => ({
  type: types.RESET_STORE_PRODUCT,
});

export const getUserGroupsAction = () => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    dispatch({ type: types.GET_USER_GROUP_REQUEST });

    try {
      const response = await ApiList.getUserGroups();
      // console.log('response',response?.data?.data)
      if (response.status == 200) {
        dispatch({
          type: types.GET_USER_GROUP_SUCCESS,
          payload: response?.data?.data,
        });
      } else {
        dispatch({
          type: types.GET_USER_GROUP_ERROR,
          payload: response.message,
        });
        AlertHelper.warning(response.message);
      }
    } catch (error) {
      dispatch({
        type: types.GET_USER_GROUP_ERROR,
        payload: error.message || error,
      });
      AlertHelper.warning(error.message || error);
    } finally {
      dispatch(apiLoadingStop());
    }
  };
};
export const getUserGroupsDetailsAction = (id) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    dispatch({ type: types.GET_USER_GROUP_DETAILS_REQUEST });

    try {
      const response = await ApiList.getUserGroupsDeatils(id);
      if (response.status == 200) {
        dispatch({
          type: types.GET_USER_GROUP_DETAILS_SUCCESS,
          payload: response?.data?.data,
        });
      } else {
        dispatch({
          type: types.GET_USER_GROUP_DETAILS_ERROR,
          payload: response.message,
        });
        AlertHelper.warning(response.message);
      }
    } catch (error) {
      dispatch({
        type: types.GET_USER_GROUP_DETAILS_ERROR,
        payload: error.message || error,
      });
      AlertHelper.warning(error.message || error);
    } finally {
      dispatch(apiLoadingStop());
    }
  };
};

export const createGroupsAction = (request, callBack) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    dispatch({ type: types.CREATE_USER_GROUP_REQUEST });

    try {
      const response = await ApiList.createGroups(request);
      if (response.status) {
        dispatch({
          type: types.CREATE_USER_GROUP_SUCCESS,
          payload: response,
        });
        if (callBack) callBack();
        toast.success("User Group Created Successfully");
      } else {
        dispatch({
          type: types.CREATE_USER_GROUP_ERROR,
          payload: response.message,
        });
        AlertHelper.warning(response.message);
      }
    } catch (error) {
      dispatch({
        type: types.CREATE_USER_GROUP_ERROR,
        payload: error.message || error,
      });
      AlertHelper.warning(error.message || error);
    } finally {
      dispatch(apiLoadingStop());
    }
  };
};
export const updateGroupsAction = (request, id, callBack) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    dispatch({ type: types.UPDATE_USER_GROUP_REQUEST });
    try {
      const response = await ApiList.updateGroups(id, request);
      if (response) {
        dispatch({
          type: types.UPDATE_USER_GROUP_SUCCESS,
          payload: response,
        });
        if (callBack) callBack();
        toast.success("User Group Updated Successfully")
      } else {
        dispatch({
          type: types.UPDATE_USER_GROUP_ERROR,
          payload: response.message,
        });
        AlertHelper.warning(response.message);
      }
    } catch (error) {
      dispatch({
        type: types.UPDATE_USER_GROUP_ERROR,
        payload: error.message || error,
      });
      AlertHelper.warning(error.message || error);
    } finally {
      dispatch(apiLoadingStop());
    }
  };
};
export const deleteUserGroupsAction = (id, callBack) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    dispatch({ type: types.DELETE_USER_GROUP_REQUEST });
    try {
      const response = await ApiList.deleteUserGroups(id);

      if (response.status == 200) {
        dispatch({
          type: types.DELETE_USER_GROUP_SUCCESS,
          payload: response,
        });
        toast.success("User Group Deleted Successfully");
        if (callBack) callBack();
      } else {
        dispatch({
          type: types.DELETE_USER_GROUP_ERROR,
          payload: response.message,
        });
        AlertHelper.warning(response.message);
      }
    } catch (error) {
      dispatch({
        type: types.DELETE_USER_GROUP_ERROR,
        payload: error.message || error,
      });
      AlertHelper.warning(error.message || error);
    } finally {
      dispatch(apiLoadingStop());
    }
  };
};

export const addUserInGroupAction = (request, id, callBack) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    dispatch({ type: types.ADD_USER_IN_GROUP_REQUEST });
    try {
      const response = await ApiList.addUserInGroup(id, request);
      if (response) {
        dispatch({
          type: types.ADD_USER_IN_GROUP_SUCCESS,
          payload: response,
        });
        if (callBack) callBack();
        toast.success("User Assigned Successfully")
      } else {
        dispatch({
          type: types.ADD_USER_IN_GROUP_ERROR,
          payload: response.message,
        });
        AlertHelper.warning(response.message);
      }
    } catch (error) {
      dispatch({
        type: types.ADD_USER_IN_GROUP_ERROR,
        payload: error.message || error,
      });
      AlertHelper.warning(error.message || error);
    } finally {
      dispatch(apiLoadingStop());
    }
  };
};
