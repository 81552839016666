import * as types from "./actionTypes";

const initialState = {
  loading: false,
  lessonList: [],
  lessonListData: null,
  errorMessage: "",
  isError: false,
  isRefrash: false,
  localStorageData: null,
  lessonId: null,
  editLessonLoading: false,
  editLessonError: "",
  lessonDetailsLoading: false,
  lessonDetails: "",
  lessonDetailsError: "",
  createLiveClassLoading: false,
  getAllLiveClassData: null,
  editLiveClassLoading: false,
  editLiveClassError: "",
  deleteLiveClassLoading:false,
  deleteLiveClassError:""
};

const LessonReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_STORE_PRODUCT:
      return {
        ...state,
      };

    //end
    case types.Lesson_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.Lesson_LIST_REFRESH_REQUEST:
      return {
        ...state,
        loading: true,
        isRefrash: true,
      };

    case types.Lesson_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        isError: false,
        isRefrash: false,
        lessonList: action.payload.data.data,
        lessonListData: action.payload.data,
      };

    case types.Lesson_LIST_ERROR:
      return {
        ...state,
        loading: false,
        isRefrash: false,
        lessonList: [],
        lessonListData: null,
        isError: true,
      };
    case types.CREATE_LESSON_REQUEST:
      return {
        ...state,
        loading: true,
        isError: false,
      };

    case types.CREATE_LESSON_SUCCESS:
      return {
        ...state,
        loading: false,
        isError: false,
        lessonId: action.payload.data,
      };

    case types.CREATE_LESSON_ERROR:
      return {
        ...state,
        loading: false,
        isError: true,
      };
    case types.UPDATE_LESSON_SETTING_REQUEST:
      return {
        ...state,
        loading: true,
        isError: false,
      };

    case types.UPDATE_LESSON_SETTING_SUCCESS:
      return {
        ...state,
        loading: false,
        isError: false,
      };

    case types.UPDATE_LESSON_SETTING_ERROR:
      return {
        ...state,
        loading: false,
        isError: true,
      };

    case types.LESSON_EDIT_REQUEST:
      return {
        ...state,
        editLessonLoading: true,
      };

    case types.LESSON_EDIT_SUCCESS:
      return {
        ...state,
        editLessonLoading: false,
      };

    case types.LESSON_EDIT_ERROR:
      return {
        ...state,
        editLessonLoading: false,
        editLessonError: action.payload.data,
      };
    case types.LESSON_DETAILS_REQUEST:
      return {
        ...state,
        lessonDetailsLoading: true,
      };

    case types.LESSON_DETAILS_SUCCESS:
      return {
        ...state,
        lessonDetailsLoading: false,
        lessonDetails: action?.payload?.data,
      };

    case types.LESSON_DETAILS_ERROR:
      return {
        ...state,
        lessonDetailsLoading: false,
        lessonDetailsError: action.payload.data,
      };
    case types.CREATE_LIVE_CLASS_REQUEST:
      return {
        ...state,
        createLiveClassLoading: true,
      };

    case types.CREATE_LIVE_CLASS_SUCCESS:
      return {
        ...state,
        createLiveClassLoading: false,
      };

    case types.CREATE_LIVE_CLASS_ERROR:
      return {
        ...state,
        createLiveClassLoading: false,
      };

    case types.LIVE_CLASS_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.LIVE_CLASS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllLiveClassData: action.payload,
      };

    case types.LIVE_CLASS_LIST_ERROR:
      return {
        ...state,
        loading: false,
      };
    case types.EDIT_LIVE_CLASS_REQUEST:
      return {
        ...state,
        editLiveClassLoading: true,
      };

    case types.EDIT_LIVE_CLASS_SUCCESS:
      return {
        ...state,
        editLiveClassLoading: false,
      };

    case types.EDIT_LIVE_CLASS_ERROR:
      return {
        ...state,
        editLiveClassLoading: false,
        editLiveClassError: action?.payload,
      };
    case types.DELETE_LIVE_CLASS_REQUEST:
      return {
        ...state,
        deleteLiveClassLoading: true,
      };

    case types.DELETE_LIVE_CLASS_SUCCESS:
      return {
        ...state,
        deleteLiveClassLoading: false,
      };

    case types.DELETE_LIVE_CLASS_ERROR:
      return {
        ...state,
        deleteLiveClassLoading: false,
        deleteLiveClassError: action?.payload,
      };

    default:
      return state;
  }
};

export default LessonReducer;
