import toast from "react-hot-toast";
import * as types from "./actionTypes";
import ApiList from "../../webservice/ApiList";
import { apiLoadingStart, apiLoadingStop } from "../global";
import { AlertHelper } from "../../Constants/AlertHelper";
export const resetStore = () => ({
  type: types.RESET_STORE_PRODUCT,
});

export const getAllTypeRolesAction = () => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    dispatch({ type: types.GET_ADMIN_ROLES_REQUEST });

    try {
      const response = await ApiList.getAdminRoles();
      if (response.status == 200) {
        dispatch({
          type: types.GET_ADMIN_ROLES_SUCCESS,
          payload: response?.data?.data?.roles,
        });
      } else {
        dispatch({
          type: types.GET_ADMIN_ROLES_ERROR,
          payload: response.message,
        });
        AlertHelper.warning(response.message);
      }
    } catch (error) {
      dispatch({
        type: types.GET_ADMIN_ROLES_ERROR,
        payload: error.message || error,
      });
      AlertHelper.warning(error.message || error);
    } finally {
      dispatch(apiLoadingStop());
    }
  };
};

export const createAdminRolesAction = (request, navigate) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    dispatch({ type: types.CREATE_ADMIN_ROLE_REQUEST });

    try {
      const response = await ApiList.createAdminRoles(request);
      if (response.status) {
        dispatch({
          type: types.CREATE_ADMIN_ROLE_SUCCESS,
          payload: response,
        });
      } else {
        dispatch({
          type: types.CREATE_ADMIN_ROLE_ERROR,
          payload: response.message,
        });
        AlertHelper.warning(response.message);
      }
    } catch (error) {
      dispatch({
        type: types.CREATE_ADMIN_ROLE_ERROR,
        payload: error.message || error,
      });
      AlertHelper.warning(error.message || error);
    } finally {
      dispatch(apiLoadingStop());
    }
  };
};
export const updateAdminRolesAction = (uuid, request, navigate) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    dispatch({ type: types.UPDATE_ADMIN_ROLE_REQUEST });
    try {
      const response = await ApiList.updateAdminRoles(uuid, request);
      if (response) {
        dispatch({
          type: types.UPDATE_ADMIN_ROLE_SUCCESS,
          payload: response,
        });
      } else {
        dispatch({
          type: types.UPDATE_ADMIN_ROLE_ERROR,
          payload: response.message,
        });
        AlertHelper.warning(response.message);
      }
    } catch (error) {
      dispatch({
        type: types.UPDATE_ADMIN_ROLE_ERROR,
        payload: error.message || error,
      });
      AlertHelper.warning(error.message || error);
    } finally {
      dispatch(apiLoadingStop());
    }
  };
};
export const deleteAdminRoleAction = (uuid) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    dispatch({ type: types.DELETE_ADMIN_ROLE_REQUEST });
    try {
      const response = await ApiList.deleteAdminRole(uuid);
      if (response.status == 200) {
        dispatch({
          type: types.DELETE_ADMIN_ROLE_SUCCESS,
          payload: response,
        });
        //navigate("/user-management")
      } else {
        dispatch({
          type: types.DELETE_ADMIN_ROLE_ERROR,
          payload: response.message,
        });
        AlertHelper.warning(response.message);
      }
    } catch (error) {
      dispatch({
        type: types.DELETE_ADMIN_ROLE_ERROR,
        payload: error.message || error,
      });
      AlertHelper.warning(error.message || error);
    } finally {
      dispatch(apiLoadingStop());
    }
  };
};

export const getAdminPermissionsAction = (flag, uuid, navigate) => {
  return async (dispatch) => {
    try {
      dispatch(apiLoadingStart());
      dispatch({ type: types.GET_ADMIN_PERMISSIONS_REQUEST });
      const response = await ApiList.getAdminPermissions();
      if (response.status == 200) {
        dispatch({
          type: types.GET_ADMIN_PERMISSIONS_SUCCESS,
          payload: response?.data?.data,
        });
        if (flag == 1) {
          {
            navigate("/role-management/update-assign-permission", {
              state: { uuid: uuid },
            });
          }
        }
      } else {
        dispatch({
          type: types.GET_ADMIN_PERMISSIONS_ERROR,
          payload: response,
        });
        // setTimeout(() => AlertHelper.warning(response?.message), 100);
        toast.error(response);
      }
    } catch (error) {
      dispatch({
        type: types.GET_ADMIN_PERMISSIONS_ERROR,
        payload: error,
      });
      // setTimeout(() => AlertHelper.warning(error), 100);
      toast.error(error);
    } finally {
      dispatch(apiLoadingStop());
    }
  };
};

export const createPermissionsAction = (request, navigate) => {
  return async (dispatch) => {
    try {
      dispatch(apiLoadingStart());
      dispatch({ type: types.CREATE_PERMISSION_REQUEST });
      const response = await ApiList.createPermissions(request);
      if (response.status == 200) {
        dispatch({
          type: types.CREATE_PERMISSION_SUCCESS,
          payload: response.data,
        });
        toast.success("Permission Create Successfully");
        navigate("/role-management");
      } else {
        dispatch({ type: types.CREATE_PERMISSION_ERROR, payload: response });
        // setTimeout(() => AlertHelper.warning(response?.message), 100);
        toast.error(response?.error?.message);
      }
    } catch (error) {
      dispatch({ type: types.CREATE_PERMISSION_ERROR, payload: error });
      // setTimeout(() => AlertHelper.warning(error), 100);
      toast.error(error);
    } finally {
      dispatch(apiLoadingStop());
    }
  };
};
export const updatePermissionsAction = (uuid, request, navigate) => {
  return async (dispatch) => {
    try {
      dispatch(apiLoadingStart());
      dispatch({ type: types.UPDATE_PERMISSION_REQUEST });
      const response = await ApiList.updatePermissions(uuid, request);
      if (response.status == 200) {
        dispatch({
          type: types.UPDATE_PERMISSION_SUCCESS,
          payload: response.data,
        });
        toast.success("Permission Update Successfully");
        // navigate("/role-management");
      } else {
        dispatch({ type: types.UPDATE_PERMISSION_ERROR, payload: response });
        // setTimeout(() => AlertHelper.warning(response?.message), 100);
        toast.error(response);
      }
    } catch (error) {
      dispatch({ type: types.UPDATE_PERMISSION_ERROR, payload: error });
      // setTimeout(() => AlertHelper.warning(error), 100);
      toast.error(error);
    } finally {
      dispatch(apiLoadingStop());
    }
  };
};

export const assignPermissionsAction = (id, request, navigate) => {
  return async (dispatch) => {
    try {
      dispatch(apiLoadingStart());
      dispatch({ type: types.ASSIGN_PERMISSION_REQUEST });
      const response = await ApiList.assignPermissions(id, request);
      if (response.status == 200) {
        dispatch({
          type: types.ASSIGN_PERMISSION_SUCCESS,
          payload: response.data,
        });
        toast.success("Role updated Successfully");
        navigate("/role-management");
        dispatch(getAllTypeRolesAction());
      } else {
        dispatch({ type: types.ASSIGN_PERMISSION_ERROR, payload: response });
        // setTimeout(() => AlertHelper.warning(response?.message), 100);
        toast.error(response);
      }
    } catch (error) {
      dispatch({ type: types.ASSIGN_PERMISSION_ERROR, payload: error });
      // setTimeout(() => AlertHelper.warning(error), 100);
      toast.error(error);
    } finally {
      dispatch(apiLoadingStop());
    }
  };
};
