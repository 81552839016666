import * as types from "./actionTypes";

const initialState = {
  loading: false,
  courseList: null,
  courseListData: [],
  errorMessage: "",
  isError: false,
  isRefrash: false,
  localStorageData: null,
  newCourseID: null,
  course_Subject_Lesson_List : null,
  settingUuid : null
};

const CourseReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_STORE_PRODUCT:
      return {
        ...state,
      };

    //end
    case types.COURSE_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.COURSE_LIST_REFRESH_REQUEST:
      return {
        ...state,
        loading: true,
        isRefrash: true,
      };

    case types.COURSE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        isError: false,
        isRefrash: false,
        courseList: action.payload.data,
        //courseListData: [...state.courseListData, ...action.payload.data],
      };

    case types.COURSE_LIST_ERROR:
      return {
        ...state,
        loading: false,
        isRefrash: false,
        courseList: null,
        courseListData: [],
        isError: true,
      };
    case types.CREATE_COURSE_REQUEST:
      return {
        ...state,
        loading: true,
        isError: false,
      };

    case types.CREATE_COURSE_SUCCESS:
      return {
        ...state,
        loading: false,
        isError: false,
        newCourseID: action.payload.data
      };

    case types.CREATE_COURSE_ERROR:
      return {
        ...state,
        loading: false,
        isError: true,
      };
    case types.UPDATE_COURSE_SETTING_REQUEST:
      return {
        ...state,
        loading: true,
        isError: false,
      };

    case types.UPDATE_COURSE_SETTING_SUCCESS:
      return {
        ...state,
        loading: false,
        isError: false,
        settingUuid: action.payload.data

      };

    case types.UPDATE_COURSE_SETTING_ERROR:
      return {
        ...state,
        loading: false,
        isError: true,
      };

      case types.COURSE_SUBJECT_LESSON_LIST_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case types.COURSE_SUBJECT_LESSON_LIST_REFRESH_REQUEST:
        return {
          ...state,
          loading: true,
          isRefrash: true,
        };
  
      case types.COURSE_SUBJECT_LESSON_LIST_SUCCESS:
        return {
          ...state,
          loading: false,
          isError: false,
          isRefrash: false,
          course_Subject_Lesson_List: action.payload,
        };
  
      case types.COURSE_SUBJECT_LESSON_LIST_ERROR:
        return {
          ...state,
          loading: false,
          isRefrash: false,
          course_Subject_Lesson_List: null,
          isError: true,
        };
    default:
      return state;
  }
};

export default CourseReducer;
