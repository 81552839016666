export const RESET_STORE_PRODUCT = 'RESET_STORE_PRODUCT';


export const COURSE_LIST_REFRESH_REQUEST ='COURSE_LIST_REFRESH_REQUEST';
export const COURSE_LIST_SUCCESS ='COURSE_LIST_SUCCESS';
export const COURSE_LIST_REQUEST = 'COURSE_LIST_REQUEST';
export const COURSE_LIST_ERROR = 'COURSE_LIST_ERROR';

export const CREATE_COURSE_REQUEST ='CREATE_COURSE_REQUEST';
export const CREATE_COURSE_SUCCESS = 'CREATE_COURSE_SUCCESS';
export const CREATE_COURSE_ERROR = 'CREATE_COURSE_ERROR';

export const UPDATE_COURSE_REQUEST ='UPDATE_COURSE_REQUEST';
export const UPDATE_COURSE_SUCCESS = 'UPDATE_COURSE_SUCCESS';
export const UPDATE_COURSE_ERROR = 'UPDATE_COURSE_ERROR';


export const UPDATE_COURSE_SETTING_REQUEST ='UPDATE_COURSE_SETTING_REQUEST';
export const UPDATE_COURSE_SETTING_SUCCESS = 'UPDATE_COURSE_SETTING_SUCCESS';
export const UPDATE_COURSE_SETTING_ERROR = 'UPDATE_COURSE_SETTING_ERROR';

export const COURSE_SUBJECT_LESSON_LIST_REFRESH_REQUEST ='COURSE_SUBJECT_LESSON_LIST_REFRESH_REQUEST';
export const COURSE_SUBJECT_LESSON_LIST_REQUEST ='COURSE_SUBJECT_LESSON_LIST_REQUEST';
export const COURSE_SUBJECT_LESSON_LIST_SUCCESS = 'COURSE_SUBJECT_LESSON_LIST_SUCCESS';
export const COURSE_SUBJECT_LESSON_LIST_ERROR = 'COURSE_SUBJECT_LESSON_LIST_ERROR';




