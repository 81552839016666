import HTTP from "./http";

class APIList {
  constructor() {}
  userAuthLogin(params) {
    return HTTP.POST("api/login", params);
  }
  getProfileData() {
    return HTTP.GET("api/v1/user/profile");
  }
  getCourseList(params) {
    return HTTP.GET("api/v1/courses", params);
  }
  createCourse(params) {
    return HTTP.POST("api/v1/courses", params);
  }
  updateCourse(params, courseId) {
    return HTTP.PUT(`api/v1/courses/${courseId}`, params);
  }
  getCourseSubjectLesson(uuid) {
    return HTTP.GET(`api/v1/all-courses/${uuid}`);
  }
  updateCourseSetting(params, courseId) {
    return HTTP.PUT(`api/v1/courses/${courseId}/settings`, params);
  }
  getSubjectList(params) {
    return HTTP.GET("api/v1/admin/subjects", params);
  }
  createSubject(params) {
    return HTTP.POST("api/v1/admin/subjects", params);
  }
  updateSubject(id, params) {
    return HTTP.PUT(`api/v1/admin/categories/${id}`, params);
  }
  deleteSubject(id) {
    return HTTP.DELETE(`api/v1/admin/categories/${id}`);
  }
  getSubjectLesson(subjectid) {
    return HTTP.GET(`api/v1/admin/subjects/${subjectid}`);
  }
  getLessonList(params, page) {
    return HTTP.GET(`api/v1/lessons?page=${page}`, params);
  }
  createLesson(params) {
    return HTTP.POST("api/v1/lessons", params);
  }
  updateLesson(params, uuid) {
    return HTTP.PUT(`api/v1/lessons/${uuid}`, params);
  }
  updateLessonSetting(params, uuid) {
    return HTTP.PUT(`api/v1/lessons/${uuid}/settings`, params);
  }
  lessonAssignToCourse(uuid, params) {
    return HTTP.PUT(`api/v1/lessons/${uuid}/assign-courses`, params);
  }
  lessonDetails(uuid) {
    return HTTP.GET(`api/v1/lessons/${uuid}`);
  }
  getAllUser() {
    return HTTP.GET("api/v1/user/all-users");
  }
  assignCourseToStudent(params) {
    return HTTP.POST("api/v1/admin/assign-course-to-user", params);
  }
  createLiveClass(params) {
    return HTTP.POST("api/v1/admin/live-classes", params);
  }
  getLiveClass(page) {
    return HTTP.GET(`api/v1/admin/live-classes?page=${page} `);
  }
  getLiveClass2(page, startDate, endDate) {
    return HTTP.GET(
      `api/v1/admin/live-classes?page=${page}&start_date=${startDate}&end_date=${endDate}`
    );
  }

  updateLiveClass(courseId, params) {
    return HTTP.POST(`api/v1/admin/live-classes/${courseId}`, params);
  }
  deleteLiveClass(id) {
    return HTTP.DELETE(`api/v1/admin/live-classes/${id}`);
  }
  getLiveClassToken(params) {
    return HTTP.POST("api/v1/generate-zoom-signature", params);
  }
  userDetails(uuid) {
    return HTTP.GET(`api/v1/user/${uuid}`);
  }
  editLiveClass(params, id) {
    return HTTP.PUT(`api/v1/admin/live-classes/${id}`, params);
  }
  createUser(params) {
    return HTTP.POST(`api/v1/admin/users/`, params);
  }

  updateUser(uuid, params) {
    // console.log(uuid, params)
    return HTTP.PUT(`api/v1/admin/users/${uuid}`, params);
  }

  deleteUser(uuid) {
    return HTTP.DELETE(`api/v1/admin/users/${uuid}`);
  }
  // assignment

  createAssignment(params) {
    return HTTP.POST("api/v1/assignments", params);
  }
  getAssignment(page) {
    return HTTP.GET(`api/v1/assignments?page=${page}`);
  }
  uploadAssignment(data, uuid) {
    return HTTP.POST3(`api/v1/assignments/${uuid}/media`, data);
  }
  getAssignmentDetails(uuid) {
    return HTTP.GET(`api/v1/assignments/${uuid}`);
  }
  updateAssignment(params, uuid) {
    return HTTP.PUT(`api/v1/assignments/${uuid}`, params);
  }
  getSubmissionAssignment(uuid) {
    return HTTP.GET(`api/v1/assignments/${uuid}/review`);
  }
  deleteAssignment(uuid) {
    return HTTP.DELETE(`api/v1/assignments/${uuid}`);
  }
  getAssignPdfLink(uuid) {
    return HTTP.GET(`api/v1/user/download/assignment/${uuid}`);
  }

  getStudentAssignPdfLink(uuid) {
    return HTTP.GET(`api/v1/assignments-submission/${uuid}/download`);
  }

  // assignment

  getAllNotification() {
    return HTTP.GET("api/v1/admin/notifications");
  }

  getNotificationDetails(id) {
    return HTTP.GET(`api/v1/admin/notifications/${id}`);
  }

  createNotification(params) {
    return HTTP.POST("api/v1/admin/notifications", params);
  }
  editNotification(id, params) {
    return HTTP.PUT(`api/v1/admin/notifications/${id}`, params);
  }

  deleteNotification(id) {
    return HTTP.DELETE(`api/v1/admin/notifications/${id}`);
  }

  // role management
  getAdminRoles() {
    return HTTP.GET("api/v1/admin/roles");
  }
  createAdminRoles(params) {
    return HTTP.POST("api/v1/admin/roles", params);
  }
  updateAdminRoles(uuid, params) {
    return HTTP.PUT(`api/v1/admin/roles/${uuid}`, params);
  }

  deleteAdminRole(uuid) {
    return HTTP.DELETE(`api/v1/admin/roles/${uuid}`);
  }

  getAdminPermissions() {
    return HTTP.GET("api/v1/admin/permissions");
  }

  createPermissions(params) {
    return HTTP.POST("api/v1/admin/permissions", params);
  }
  updatePermissions(uuid,params) {
    return HTTP.PUT(`api/v1/admin/permissions/${uuid}`, params);
  }
  assignPermissions(id, params) {
    return HTTP.POST(`api/v1/admin/roles/${id}/assign-permissions`, params);
  }
  // user groups
  getUserGroups(){
    return HTTP.GET("api/v1/admin/user-groups");
  }
  getUserGroupsDeatils(id){
    return HTTP.GET(`api/v1/admin/user-groups/${id}`);
  }
  createGroups(params){
    return HTTP.POST("api/v1/admin/user-groups",params);
  }
  updateGroups(id,params){
    return HTTP.PUT(`api/v1/admin/user-groups/${id}`,params);
  }
  deleteUserGroups(id){
    return HTTP.DELETE(`api/v1/admin/user-groups/${id}`);
  }

  addUserInGroup(id, params){
    return HTTP.POST(`api/v1/admin/user-groups/${id}/assign-users`,params);
  }
  // assignAssignmentToStudent(uuid,params){
  //   console.log('uuid,params',`api/v1/assignments/${uuid}/assign-usergroups`)
  //   return HTTP.POST(`api/v1/assignments/${uuid}/assign-usergroups`,params);
  // }

  assignAssignmentToStudent(uuid,params) {
    console.log('uuid,params',`api/v1/assignments/${uuid}/assign-usergroups`)
    return HTTP.POST(`api/v1/assignments/${uuid}/assign-usergroups`, params);
  }



}

export default new APIList();
