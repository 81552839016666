import React from "react";

export const guestRoutes = [
  {
    path: "/signin",
    name: "Signin",
    exact: true,
    component: React.lazy(() => import("../../views/auth/Login/Login")),
  },
];

export const userRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    exact: true,
    component: React.lazy(() => import("../../views/user/Dashboard/Dashboard")),
  },
  {
    path: "/courses/create-new-course",
    name: "CreateNewCourse",
    requiredPermission: "create-course",
    exact: true,
    component: React.lazy(() =>
      import("../../views/user/CreateCourse/CreateCourse")
    ),
  },
  {
    path: "/subject/create-subject",
    name: "CreateSubject",
    requiredPermission: "create-subject",
    exact: true,
    component: React.lazy(() =>
      import("../../views/user/CreateSubject/CreateSubject")
    ),
  },
  {
    path: "/course/edit-course",
    name: "EditCourse",
    requiredPermission: "update-course",
    exact: true,
    component: React.lazy(() =>
      import("../../views/user/CreateCourse/CreateCourse")
    ),
  },
  {
    path: "/subject/edit-subject",
    name: "EditSubject",
    requiredPermission: "update-subject",
    exact: true,
    component: React.lazy(() => import("../../views/user/Subject/EditSubject")),
  },
  {
    path: "/lesson/create-lesson",
    name: "CreateLesson",
    requiredPermission: "create-lesson",
    exact: true,
    component: React.lazy(() =>
      import("../../views/user/CreateLesson/CreateLesson")
    ),
  },
  {
    path: "/lesson/edit-lesson",
    name: "EditLesson",
    requiredPermission: "update-lesson",
    exact: true,
    component: React.lazy(() =>
      import("../../views/user/CreateLesson/CreateLesson")
    ),
  },
  {
    path: "/lesson/live-class",
    name: "LiveClass",
    requiredPermission: "view-lesson",
    exact: true,
    component: React.lazy(() => import("../../views/user/Lesson/LiveClass")),
  },
  {
    path: "/live-class",
    name: "AllLiveClass",
    requiredPermission: "view-liveClass",
    exact: true,
    component: React.lazy(() =>
      import("../../views/user/AllLiveClass/AllLiveClass")
    ),
  },
  {
    path: "/courses",
    name: "Courses",
    requiredPermission: "view-course",
    exact: true,
    component: React.lazy(() => import("../../views/user/Course/Course")),
  },
  {
    path: "/subject",
    name: "Subject",
    requiredPermission: "view-subject",
    exact: true,
    component: React.lazy(() => import("../../views/user/Subject/Subject")),
  },
  {
    path: "/lesson",
    name: "Lesson",
    requiredPermission: "view-lesson",
    exact: true,
    component: React.lazy(() => import("../../views/user/Lesson/Lesson")),
  },
  {
    path: "/profile",
    name: "Profile",
    exact: true,
    component: React.lazy(() => import("../../views/user/Profile/profile")),
  },
  {
    path: "/subject/:name",
    requiredPermission: "view-subject",
    name: "Profile",
    exact: true,
    component: React.lazy(() => import("../../views/user/Subject/ViewSubject")),
  },
  {
    path: "/user-management",
    name: "userManagement",
    requiredPermission: "view-user",
    exact: true,
    component: React.lazy(() =>
      import("../../views/user/UserManagement/UserManagement")
    ),
  },
  {
    path: "/course-assign",
    name: "courseassign",
    requiredPermission: "assign-user",
    exact: true,
    component: React.lazy(() =>
      import("../../views/user/CourseAssign/CourseAssign")
    ),
  },

  {
    path: "/course-detail",
    name: "coursedetail",
    requiredPermission: "view-course",
    exact: true,
    component: React.lazy(() =>
      import("../../views/user/CourseDetails/CourseDetails")
    ),
  },
  {
    path: "/join-meeting",
    name: "JoinMeeting",
    exact: true,
    component: React.lazy(() =>
      import("../../views/user/JoinMeeting/JoinMeeting")
    ),
  },
  {
    path: "/user-management/view-user",
    name: "JoinMeeting",
    requiredPermission: "view-user",
    exact: true,
    component: React.lazy(() =>
      import("../../views/user/UserManagement/ViewUser")
    ),
  },
  {
    path: "/user-management/create-user",
    name: "usermanagement",
    requiredPermission: "create-user",
    exact: true,
    component: React.lazy(() =>
      import("../../views/user/UserManagement/CreateUser")
    ),
  },
  {
    path: "/notification",
    name: "notification",
    requiredPermission: "view-notification",
    exact: true,
    component: React.lazy(() =>
      import("../../views/user/Notification/Notification")
    ),
  },
  {
    path: "/create-notification",
    name: "create-notification",
    requiredPermission: "create-notifications",
    exact: true,
    component: React.lazy(() =>
      import("../../views/user/Notification/CreateNotification")
    ),
  },
  {
    path: "/assignment",
    name: "assignment",
    requiredPermission: "view-assignment",
    exact: true,
    component: React.lazy(() =>
      import("../../views/user/Assignment/Assignment")
    ),
  },
  {
    path: "/assignment/assignment-submission-list",
    name: "assignmentsubmissionlist",
    requiredPermission: "view-assignment",
    exact: true,
    component: React.lazy(() =>
      import(
        "../../views/user/AssignmentSubmissionList/AssignmentSubmissionList"
      )
    ),
  },
  {
    path: "/assignment/create-assignment",
    name: "createassignment",
    requiredPermission: "create-assignment",
    exact: true,
    component: React.lazy(() =>
      import("../../views/user/CreateAssignment/CreateAssignment")
    ),
  },
  {
    path: "/course-assign-multiple-student",
    name: "courseassignmultiplestudent",
    requiredPermission: "assign-multiUser",
    exact: true,
    component: React.lazy(() =>
      import(
        "../../views/user/CourseAssignMultipleStudent/CourseAssignMultipleStudent"
      )
    ),
  },
  {
    path: "/assignment/edit-assignment",
    name: "createassignment",
    requiredPermission: "update-assignment",
    exact: true,
    component: React.lazy(() =>
      import("../../views/user/CreateAssignment/CreateAssignment")
    ),
  },
  {
    path: "/user-management/edit-user",
    name: "usermanagement",
    requiredPermission: "update-user",
    exact: true,
    component: React.lazy(() =>
      import("../../views/user/UserManagement/EditUser")
    ),
  },
  {
    redirectRoute: true,
    name: "dashboardRedirect",
    path: "/dashboard",
  },
  {
    path: "/role-management",
    name: "rolemanagement",
    requiredPermission: "view-role",
    exact: true,
    component: React.lazy(() =>
      import("../../views/user/RoleManagement/RoleManagement")
    ),
    
  },
  {
    path: "/role-management/create-permission",
    name: "createpermission",
    exact: true,
    requiredPermission: "create-role",
    component: React.lazy(() =>
      import("../../views/user/RoleManagement/CreatePermission")
    ),
    
  },
  {
    path: "/role-management/update-assign-permission",
    name: "updateAssignPermission",
    requiredPermission: "update-assign",
    exact: true,
    component: React.lazy(() =>
      import("../../views/user/RoleManagement/UpdateAssignPermission"),
    ),
  },
  {
    path: "/role-management/permission-list",
    name: "permissionList",
    requiredPermission: "list-permissionlist",
    exact: true,
    component: React.lazy(() =>
      import("../../views/user/RoleManagement/PermissionList"),
    ),
  },
  {
    path: "/role-management/edit-permission",
    name: "editPermission",
    requiredPermission: "update-permission",
    exact: true,
    component: React.lazy(() =>
      import("../../views/user/RoleManagement/EditPermission"),
    ),
  },
  {
    path: "/report",
    name: "Report",
    requiredPermission: "view-report",
    exact: true,
    component: React.lazy(() =>
      import("../../views/user/Report/Report"),
    ),
  },
  {
    path: "/error/accesss-denied/403",
    name: "ErrorPage",
    exact: true,
    component: React.lazy(() => import("../../views/Error")), // Error page component
  },
  {
    path: "/user-group",
    name: "UserGroup",
    requiredPermission: "view-usergroups",
    exact: true,
    component: React.lazy(() => import("../../views/user/UserGroup/UserGroup")),
  },
  {
    path: "/user-group/details",
    name: "UserGroup",
    requiredPermission: "view-usergroups",
    exact: true,
    component: React.lazy(() => import("../../views/user/UserGroup/ViewUserGroup")),
  },
  {
    path: "/calender",
    name: "calender",
    requiredPermission: "view-liveClass",
    exact: true,
    component: React.lazy(() => import("../../views/user/LiveClassCalender/LiveClassCalender")),
  },
];

export const PrivacyPolicy =
  {
    path: "/privacy-policy",
    name: "privacy",
    exact: true,
    component: React.lazy(() => import("../../views/PrivacyPolicy")),
  }

