import * as types from "./actionTypes";

const initialState = {
  loading: false,
  assignmentList: null,
  assignmentListData: [],
  errorMessage: "",
  isError: false,
  isRefrash: false,
  newAssignmentID: null,
  submissionList: [],
  // settingUuid : null
  assignmentDetails: "",
  assignmentDetailsError: "",
  assignmentDetailsLoading: "",
  updateAssignmentError: "",
  updateAssignmentLoading: "",
  updateUplaodAssignmentError: "",
  updateUploadAssignmentLoading: "",
  deleteAssignmentError: "",
  deleteAssignmentLoading: "",
  assignPdfLink: "",
  assignPdfLinkLoading: "",
  assignPdfLinkErr: "",
  studentAssignPdfLink: ''
  
};

const CourseReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_STORE_PRODUCT:
      return {
        ...state,
      };

    //end
    case types.ASSIGNMENT_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.ASSIGNMENT_LIST_REFRESH_REQUEST:
      return {
        ...state,
        loading: true,
        isRefrash: true,
      };

    case types.ASSIGNMENT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        isError: false,
        isRefrash: false,
        assignmentList: action.payload,
      };

    case types.ASSIGNMENT_LIST_ERROR:
      return {
        ...state,
        loading: false,
        isRefrash: false,
        assignmentList: null,
        assignmentListData: [],
        isError: true,
      };
    case types.CREATE_ASSIGNMENT_REQUEST:
      return {
        ...state,
        loading: true,
        isError: false,
      };

    case types.CREATE_ASSIGNMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        isError: false,
        newAssignmentID: action.payload.data,
      };

    case types.CREATE_ASSIGNMENT_ERROR:
      return {
        ...state,
        loading: false,
        isError: true,
      };
    case types.UPLOAD_ASSIGNMENT_REQUEST:
      return {
        ...state,
        loading: true,
        isError: false,
      };

    case types.UPLOAD_ASSIGNMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        isError: false,
        // settingUuid: action.payload.data
      };

    case types.UPLOAD_ASSIGNMENT_ERROR:
      return {
        ...state,
        loading: false,
        isError: true,
      };

    case types.SUBMISSION_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.SUBMISSION_LIST_REFRESH_REQUEST:
      return {
        ...state,
        loading: true,
        isRefrash: true,
      };

    case types.SUBMISSION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        isError: false,
        isRefrash: false,
        submissionList: action.payload.submission,
      };

    case types.SUBMISSION_LIST_ERROR:
      return {
        ...state,
        loading: false,
        isRefrash: false,
        submissionList: [],
        isError: true,
      };

    case types.ASSIGNMENT_DETAILS_REQUEST:
      return {
        ...state,
        assignmentDetailsLoading: true,
      };
    case types.ASSIGNMENT_DETAILS_SUCCESS:
      return {
        ...state,
        assignmentDetailsLoading: false,
        assignmentDetails: action.payload.data,
      };
    case types.ASSIGNMENT_DETAILS_ERROR:
      return {
        ...state,
        assignmentDetailsLoading: false,
        assignmentDetailsError: action.payload.data,
      };

    case types.UPDATE_ASSIGNMENT_REQUEST:
      return {
        ...state,
        updateAssignmentLoading: true,
      };
    case types.UPDATE_ASSIGNMENT_SUCCESS:
      return {
        ...state,
        updateAssignmentLoading: false,
      };
    case types.UPDATE_ASSIGNMENT_ERROR:
      return {
        ...state,
        updateAssignmentLoading: false,
        updateAssignmentError: action.payload.data,
      };

    case types.UPDATE_UPLOAD_ASSIGNMENT_REQUEST:
      return {
        ...state,
        updateUploadAssignmentLoading: true,
      };
    case types.UPDATE_UPLOAD_ASSIGNMENT_SUCCESS:
      return {
        ...state,
        updateUploadAssignmentLoading: false,
      };
    case types.UPDATE_UPLOAD_ASSIGNMENT_ERROR:
      return {
        ...state,
        updateUploadAssignmentLoading: false,
        updateUplaodAssignmentError: action.payload.data,
      };

    case types.DELETE_ASSIGNMENT_REQUEST:
      return {
        ...state,
        deleteAssignmentLoading: true,
      };
    case types.DELETE_ASSIGNMENT_SUCCESS:
      return {
        ...state,
        deleteAssignmentLoading: false,
      };
    case types.DELETE_ASSIGNMENT_ERROR:
      return {
        ...state,
        deleteAssignmentLoading: false,
        deleteAssignmentError: action.payload.data,
      };

    case types.GET_ASSIGN_PDF_LINK_REQUEST:
      return {
        ...state,
        assignPdfLinkLoading: true,
      };
    case types.GET_ASSIGN_PDF_LINK_SUCCESS:
      return {
        ...state,
        assignPdfLinkLoading: false,
        assignPdfLink:action.payload.data
      };
    case types.GET_ASSIGN_PDF_LINK_ERROR:
      return {
        ...state,
        assignPdfLinkLoading: false,
        assignPdfLinkErr: action.payload.data,
      };

      case types.STUDENT_GET_ASSIGN_PDF_LINK_REQUEST:
      return {
        ...state,
        assignPdfLinkLoading: true,
      };
    case types.STUDENT_GET_ASSIGN_PDF_LINK_SUCCESS:
      return {
        ...state,
        assignPdfLinkLoading: false,
        studentAssignPdfLink:action.payload.data
      };
    case types.STUDENT_GET_ASSIGN_PDF_LINK_ERROR:
      return {
        ...state,
        assignPdfLinkLoading: false,
        assignPdfLinkErr: action.payload.data,
      };

    default:
      return state;
  }
};

export default CourseReducer;
