import * as types from "./actionTypes";

const initialState = {
  loading: false,
  allNotificationList: [],
  notificationDetails: null,
  errorMessage: "",
  isError: false,
  isRefrash: false,
};

const NotificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_STORE_PRODUCT:
      return {
        ...state,
      };

    //end
    case types.ALL_NOTIFICATION_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.ALL_NOTIFICATION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        isError: false,
        isRefrash: false,
        allNotificationList: action.payload,
      };

    case types.ALL_NOTIFICATION_LIST_ERROR:
      return {
        ...state,
        loading: false,
        isRefrash: false,
        allNotificationList: [],
        isError: true,
      };

    case types.NOTIFICATION_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.NOTIFICATION_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        notificationDetails: action.payload,
      };

    case types.NOTIFICATION_DETAILS_ERROR:
      return {
        ...state,
        loading: false,
        notificationDetails: null,
      };
    case types.CERETE_NOTIFICATION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.CERETE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case types.CERETE_NOTIFICATION_ERROR:
      return {
        ...state,
        loading: false,
      };

    case types.UPDATE_NOTIFICATION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.UPDATE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case types.UPDATE_NOTIFICATION_ERROR:
      return {
        ...state,
        loading: false,
      };

    case types.NOTIFICATION_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.NOTIFICATION_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case types.NOTIFICATION_DELETE_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};

export default NotificationReducer;
