import toast from "react-hot-toast";
import * as types from "./actionTypes";
import ApiList from "../../webservice/ApiList";
import { apiLoadingStart, apiLoadingStop } from "../global";
import { AlertHelper } from "../../Constants/AlertHelper";
export const resetStore = () => ({
  type: types.RESET_STORE_PRODUCT,
});

export const getCourseListAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());

    const requestType = request?.isRefresh
      ? types.COURSE_LIST_REFRESH_REQUEST
      : types.COURSE_LIST_REQUEST;

    dispatch({ type: requestType });

    try {
      const response = await ApiList.getCourseList(request);
      if (response.status == 200) {
        dispatch({ type: types.COURSE_LIST_SUCCESS, payload: response.data });
      } else {
        dispatch({ type: types.COURSE_LIST_ERROR, payload: response.message });
        AlertHelper.warning(response.message);
      }
    } catch (error) {
      dispatch({
        type: types.COURSE_LIST_ERROR,
        payload: error.message || error,
      });
      AlertHelper.warning(error.message || error);
    } finally {
      dispatch(apiLoadingStop());
    }
  };
};

export const createCourseAction = (
  request,
  onStepChange,
  setIsStep1Complete
) => {
  return async (dispatch) => {
    try {
      dispatch(apiLoadingStart());
      dispatch({ type: types.CREATE_COURSE_REQUEST });
      const response = await ApiList.createCourse(request);
      if (response.status === 201) {
        dispatch({ type: types.CREATE_COURSE_SUCCESS, payload: response.data });
        if (onStepChange) onStepChange();
        if (setIsStep1Complete) setIsStep1Complete(true);
      } else {
        dispatch({ type: types.CREATE_COURSE_ERROR, payload: response });
        // setTimeout(() => AlertHelper.warning(response?.message), 100);
        toast.error(response?.data?.title);
      }
    } catch (error) {
      dispatch({ type: types.CREATE_COURSE_SUCCESS, payload: error });
      // setTimeout(() => AlertHelper.warning(error), 100);
      toast.error(error);
    } finally {
      dispatch(apiLoadingStop());
    }
  };
};


export const updateCourseAction = (
  id,
  request,
  onStepChange,
  setIsStep1Complete
) => {
  return async (dispatch) => {
    try {
      dispatch(apiLoadingStart());
      dispatch({ type: types.UPDATE_COURSE_REQUEST });
      const response = await ApiList.updateCourse(request,id);
      if (response.status === 200) {
        dispatch({ type: types.UPDATE_COURSE_SUCCESS, payload: response.data });
        if (onStepChange) onStepChange();
        if (setIsStep1Complete) setIsStep1Complete(true);
      } else {
        dispatch({ type: types.UPDATE_COURSE_ERROR, payload: response });
        // setTimeout(() => AlertHelper.warning(response?.message), 100);
        toast.error(response?.data?.title);
      }
    } catch (error) {
      dispatch({ type: types.UPDATE_COURSE_ERROR, payload: error });
      // setTimeout(() => AlertHelper.warning(error), 100);
      toast.error(error);
    } finally {
      dispatch(apiLoadingStop());
    }
  };
};


export const updateCourseSettingAction = ({
  request,
  id,
  onStepChange,
  setIsStep2Complete,
  uuid,
  navigate,
}) => {
  return async (dispatch) => {
    try {
      dispatch(apiLoadingStart());
      dispatch({ type: types.UPDATE_COURSE_SETTING_REQUEST });

      const response = await ApiList.updateCourseSetting(request, id);
      if (response) {
        dispatch({
          type: types.UPDATE_COURSE_SETTING_SUCCESS,
          payload: response,
        });
        if (onStepChange) onStepChange();
        if (setIsStep2Complete) setIsStep2Complete(true);
        let flag = 0;
        dispatch(getCourseSubjectLessonAction(uuid, navigate, flag));
      } else {
        dispatch({
          type: types.UPDATE_COURSE_SETTING_ERROR,
          payload: response,
        });
        // setTimeout(() => AlertHelper.warning(response), 100);
        toast.error(response);
      }
    } catch (error) {
      dispatch({ type: types.UPDATE_COURSE_SETTING_SUCCESS, payload: error });
      // setTimeout(() => AlertHelper.warning(error), 100);
      toast.error(error);
    } finally {
      dispatch(apiLoadingStop());
    }
  };
};

export const getCourseSubjectLessonAction = (uuid, navigate, flag) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());

    const requestType = uuid?.isRefresh
      ? types.COURSE_SUBJECT_LESSON_LIST_REFRESH_REQUEST
      : types.COURSE_SUBJECT_LESSON_LIST_REQUEST;

    dispatch({ type: requestType });

    try {
      const response = await ApiList.getCourseSubjectLesson(uuid);
      if (response.status == 200) {
        // navigate("/course");
        dispatch({
          type: types.COURSE_SUBJECT_LESSON_LIST_SUCCESS,
          payload: response?.data?.data?.details,
        });
        if (flag == 1) {
          navigate("/course-detail");
        }
      } else {
        dispatch({
          type: types.COURSE_SUBJECT_LESSON_LIST_ERROR,
          payload: response.message,
        });
        AlertHelper.warning(response.message);
      }
    } catch (error) {
      dispatch({
        type: types.COURSE_SUBJECT_LESSON_LIST_ERROR,
        payload: error.message || error,
      });
      AlertHelper.warning(error.message || error);
    } finally {
      dispatch(apiLoadingStop());
    }
  };
};
