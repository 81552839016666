export const RESET_STORE_PRODUCT = "RESET_STORE_PRODUCT";

export const GET_USER_GROUP_REQUEST = "GET_USER_GROUP_REQUEST";
export const GET_USER_GROUP_SUCCESS = "GET_USER_GROUP_SUCCESS";
export const GET_USER_GROUP_ERROR = "GET_USER_GROUP_ERROR";

export const GET_USER_GROUP_DETAILS_REQUEST = "GET_USER_GROUP_DETAILS_REQUEST";
export const GET_USER_GROUP_DETAILS_SUCCESS = "GET_USER_GROUP_DETAILS_SUCCESS";
export const GET_USER_GROUP_DETAILS_ERROR = "GET_USER_GROUP_DETAILS_ERROR";

export const CREATE_USER_GROUP_REQUEST = 'CREATE_USER_GROUP_REQUEST';
export const CREATE_USER_GROUP_SUCCESS = 'CREATE_USER_GROUP_SUCCESS';
export const CREATE_USER_GROUP_ERROR = 'CREATE_USER_GROUP_ERROR';

export const UPDATE_USER_GROUP_REQUEST = 'UPDATE_USER_GROUP_REQUEST';
export const UPDATE_USER_GROUP_SUCCESS = 'UPDATE_USER_GROUP_SUCCESS';
export const UPDATE_USER_GROUP_ERROR = 'UPDATE_USER_GROUP_ERROR';


export const DELETE_USER_GROUP_REQUEST = 'DELETE_USER_GROUP_REQUEST';
export const DELETE_USER_GROUP_SUCCESS = 'DELETE_USER_GROUP_SUCCESS';
export const DELETE_USER_GROUP_ERROR = 'DELETE_USER_GROUP_ERROR';

export const ADD_USER_IN_GROUP_REQUEST = 'ADD_USER_IN_GROUP_REQUEST';
export const ADD_USER_IN_GROUP_SUCCESS = 'ADD_USER_IN_GROUP_SUCCESS';
export const ADD_USER_IN_GROUP_ERROR = 'ADD_USER_IN_GROUP_ERROR';


