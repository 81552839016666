export const RESET_STORE_PRODUCT = "RESET_STORE_PRODUCT";

export const GET_ADMIN_ROLES_REQUEST = "GET_ADMIN_ROLES_REQUEST";
export const GET_ADMIN_ROLES_SUCCESS = "GET_ADMIN_ROLES_SUCCESS";
export const GET_ADMIN_ROLES_ERROR = "GET_ADMIN_ROLES_ERROR";

export const CREATE_ADMIN_ROLE_REQUEST = "CREATE_ADMIN_ROLE_REQUEST";
export const CREATE_ADMIN_ROLE_SUCCESS = "CREATE_ADMIN_ROLE_SUCCESS";
export const CREATE_ADMIN_ROLE_ERROR = "CREATE_ADMIN_ROLE_ERROR";

export const UPDATE_ADMIN_ROLE_REQUEST = 'UPDATE_ADMIN_ROLE_REQUEST';
export const UPDATE_ADMIN_ROLE_SUCCESS = 'UPDATE_ADMIN_ROLE_SUCCESS';
export const UPDATE_ADMIN_ROLE_ERROR = 'UPDATE_ADMIN_ROLE_ERROR';

export const DELETE_ADMIN_ROLE_REQUEST = 'DELETE_ADMIN_ROLE_REQUEST';
export const DELETE_ADMIN_ROLE_SUCCESS = 'DELETE_ADMIN_ROLE_SUCCESS';
export const DELETE_ADMIN_ROLE_ERROR = 'DELETE_ADMIN_ROLE_ERROR';

export const GET_ADMIN_PERMISSIONS_REQUEST = 'GET_ADMIN_PERMISSIONS_REQUEST'
export const GET_ADMIN_PERMISSIONS_SUCCESS = 'GET_ADMIN_PERMISSIONS_SUCCESS'
export const GET_ADMIN_PERMISSIONS_ERROR = 'GET_ADMIN_PERMISSIONS_ERROR'

export const CREATE_PERMISSION_REQUEST = 'CREATE_PERMISSION_REQUEST'
export const CREATE_PERMISSION_SUCCESS = 'CREATE_PERMISSION_SUCCESS'
export const CREATE_PERMISSION_ERROR = 'CREATE_PERMISSION_ERROR'

export const UPDATE_PERMISSION_REQUEST = 'UPDATE_PERMISSION_REQUEST'
export const UPDATE_PERMISSION_SUCCESS = 'UPDATE_PERMISSION_SUCCESS'
export const UPDATE_PERMISSION_ERROR = 'UPDATE_PERMISSION_ERROR'

export const ASSIGN_PERMISSION_REQUEST = 'ASSIGN_PERMISSION_REQUEST'
export const ASSIGN_PERMISSION_SUCCESS = 'ASSIGN_PERMISSION_SUCCESS'
export const ASSIGN_PERMISSION_ERROR = 'ASSIGN_PERMISSION_ERROR'
