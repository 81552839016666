import toast from "react-hot-toast";
import * as types from "./actionTypes";
import ApiList from "../../webservice/ApiList";
import { apiLoadingStart, apiLoadingStop } from "../global";
import { AlertHelper } from "../../Constants/AlertHelper";
export const resetStore = () => ({
  type: types.RESET_STORE_PRODUCT,
});

export const getAllUserListAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    dispatch({ type: types.ALL_USER_LIST_REQUEST });

    try {
      const response = await ApiList.getAllUser(request);
      if (response) {
        dispatch({ type: types.ALL_USER_LIST_SUCCESS, payload: response });
      } else {
        dispatch({
          type: types.ALL_USER_LIST_ERROR,
          payload: response.message,
        });
        AlertHelper.warning(response.message);
      }
    } catch (error) {
      dispatch({
        type: types.ALL_USER_LIST_ERROR,
        payload: error.message || error,
      });
      AlertHelper.warning(error.message || error);
    } finally {
      dispatch(apiLoadingStop());
    }
  };
};

export const assignCourseToStudentAction = (request, navigate) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    dispatch({ type: types.ASSIGN_COURSE_TO_USER_REQUEST });

    try {
      const response = await ApiList.assignCourseToStudent(request);
      if (response.status) {
        dispatch({
          type: types.ASSIGN_COURSE_TO_USER_SUCCESS,
          payload: response,
        });
        navigate("/user-management");
      } else {
        dispatch({
          type: types.USER_MANAGEMENT_DETAILS_ERROR,
          payload: response.message,
        });
        AlertHelper.warning(response.message);
      }
    } catch (error) {
      dispatch({
        type: types.ASSIGN_COURSE_TO_USER_ERROR,
        payload: error.message || error,
      });
      AlertHelper.warning(error.message || error);
    } finally {
      dispatch(apiLoadingStop());
    }
  };
};
export const userMangementDetaisAction = (uuid, flag, navigate) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    dispatch({ type: types.USER_MANAGEMENT_DATAILS_REQUEST });
    try {
      const response = await ApiList.userDetails(uuid);
      if (response) {
        dispatch({
          type: types.USER_MANAGEMENT_DETAILS_SUCCESS,
          payload: response,
        });
        if(flag == 1){
          navigate("/user-management/edit-user")
        }
      } else {
        dispatch({
          type: types.USER_MANAGEMENT_DETAILS_ERROR,
          payload: response.message,
        });
        AlertHelper.warning(response.message);
      }
    } catch (error) {
      dispatch({
        type: types.USER_MANAGEMENT_DETAILS_ERROR,
        payload: error.message || error,
      });
      AlertHelper.warning(error.message || error);
    } finally {
      dispatch(apiLoadingStop());
    }
  };
};
export const createUserAction = (request,navigate) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    dispatch({ type: types.CREATE_USER_REQUEST });
    try {
      const response = await ApiList.createUser(request);
      if (response.status == 200) {
        dispatch({
          type: types.CREATE_USER_SUCCESS,
          payload: response,
        });
        navigate("/user-management")

      } else {
        dispatch({
          type: types.CREATE_USER_ERROR,
          payload: response.message,
        });
        AlertHelper.warning(response.message);
      }
    } catch (error) {
      dispatch({
        type: types.CREATE_USER_ERROR,
        payload: error.message || error,
      });
      AlertHelper.warning(error.message || error);
    } finally {
      dispatch(apiLoadingStop());
    }
  };
};

export const deleteUserAction = (uuid, setModalShow, callBack) => {
  return async (dispatch) => {
    try {
      dispatch(apiLoadingStart());
      dispatch({ type: types.DELETE_USER_REQUEST });
      const response = await ApiList.deleteUser(uuid);
      if (response.status === 200) {
        dispatch({
          type: types.DELETE_USER_SUCCESS,
          payload: response.data,
        });
        toast.success("User Deleted Successfully");
        setModalShow(false);
        if (callBack) callBack();
      } else {
        dispatch({
          type: types.DELETE_USER_ERROR,
          payload: response,
        });
        // setTimeout(() => AlertHelper.warning(response?.message), 100);
        toast.error(response);
      }
    } catch (error) {
      dispatch({
        type: types.DELETE_USER_ERROR,
        payload: error,
      });
      // setTimeout(() => AlertHelper.warning(error), 100);
      toast.error(error);
    } finally {
      dispatch(apiLoadingStop());
    }
  };
};


export const updateUserAction = ( uuid, request,navigate) => {
  return async (dispatch) => {
    try {
      dispatch(apiLoadingStart());
      dispatch({ type: types.UPDATE_USER_REQUEST });
      const response = await ApiList.updateUser(uuid, request);
      if (response.status == 200) {
        dispatch({
          type: types.UPDATE_USER_SUCCESS,
          payload: response.data,
        });
        toast.success("PdfUser Uploaded Successfully");
        navigate("/user-management");
      } else {
        dispatch({ type: types.UPDATE_USER_ERROR, payload: response });
        // setTimeout(() => AlertHelper.warning(response?.message), 100);
        toast.error(response);
      }
    } catch (error) {
      dispatch({ type: types.UPDATE_USER_ERROR, payload: error });
      // setTimeout(() => AlertHelper.warning(error), 100);
      toast.error(error);
    } finally {
      dispatch(apiLoadingStop());
    }
  };
};

