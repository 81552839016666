import * as types from "./actionTypes";
import ApiList from "../../webservice/ApiList";
import { apiLoadingStart, apiLoadingStop } from "../global";
import { AlertHelper } from "../../Constants/AlertHelper";
import toast from "react-hot-toast";

export const resetStore = () => ({
  type: types.RESET_STORE_PRODUCT,
});

export const getAllNotificationListAction = () => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    dispatch({ type: types.ALL_NOTIFICATION_LIST_REQUEST });

    try {
      const response = await ApiList.getAllNotification();
      if (response) {
        dispatch({
          type: types.ALL_NOTIFICATION_LIST_SUCCESS,
          payload: response.data.data[1],
        });
      } else {
        dispatch({
          type: types.ALL_NOTIFICATION_LIST_ERROR,
          payload: response.message,
        });
        AlertHelper.warning(response.message);
      }
    } catch (error) {
      dispatch({
        type: types.ALL_NOTIFICATION_LIST_ERROR,
        payload: error.message || error,
      });
      AlertHelper.warning(error.message || error);
    } finally {
      dispatch(apiLoadingStop());
    }
  };
};

export const getNotificationDetailsAction = (
  id,
  notificationtype,
  navigate
) => {
  try {
    return async (dispatch) => {
      dispatch(apiLoadingStart());
      dispatch({ type: types.NOTIFICATION_DETAILS_REQUEST });
      try {
        const response = await ApiList.getNotificationDetails(id);
        if (response.status == 200) {
          dispatch({
            type: types.NOTIFICATION_DETAILS_SUCCESS,
            payload: response.data.data[1],
          });
          navigate("/create-notification", {
            state: { type: notificationtype },
          });
        } else {
          dispatch({
            type: types.NOTIFICATION_DETAILS_ERROR,
            payload: response.message,
          });
          AlertHelper.warning(response.message);
        }
      } catch (error) {
        dispatch({
          type: types.NOTIFICATION_DETAILS_ERROR,
          payload: error.message || error,
        });
        AlertHelper.warning(error.message || error);
      } finally {
        dispatch(apiLoadingStop());
      }
    };
  } catch (err) {
    // console.log("err", err);
  }
};

export const deleteNotificationAction = (id, setModalShow, navigate) => {
  return async (dispatch) => {
    try {
      dispatch(apiLoadingStart());
      dispatch({ type: types.NOTIFICATION_DELETE_REQUEST });
      const response = await ApiList.deleteNotification(id);
      if (response) {
        dispatch({
          type: types.NOTIFICATION_DELETE_SUCCESS,
          payload: response,
        });
        toast.success(response?.message);
        // navigate("/lesson")
        setModalShow(false);
        window.location.reload();
      } else {
        dispatch({ type: types.NOTIFICATION_DELETE_ERROR, payload: response });
        setTimeout(() => AlertHelper.warning(response), 100);
      }
    } catch (error) {
      dispatch({ type: types.NOTIFICATION_DELETE_ERROR, payload: error });
      setTimeout(() => AlertHelper.warning(error), 100);
    } finally {
      dispatch(apiLoadingStop());
    }
  };
};

export const createNotificationAction = (request, navigate) => {
  return async (dispatch) => {
    try {
      dispatch(apiLoadingStart());
      dispatch({ type: types.CERETE_NOTIFICATION_REQUEST });
      const response = await ApiList.createNotification(request);
      if (response.status == 200) {
        navigate("/notification");
        // window.location.reload();
        toast.success(response?.message);
        dispatch({
          type: types.CERETE_NOTIFICATION_SUCCESS,
          payload: response,
        });
      } else {
        dispatch({ type: types.CERETE_NOTIFICATION_ERROR, payload: response });
        setTimeout(() => AlertHelper.warning(response), 100);
      }
    } catch (error) {
      dispatch({ type: types.CERETE_NOTIFICATION_ERROR, payload: error });
      setTimeout(() => AlertHelper.warning(error), 100);
    } finally {
      dispatch(apiLoadingStop());
    }
  };
};

export const editNotificationAction = (id, request, navigate) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    dispatch({ type: types.UPDATE_NOTIFICATION_REQUEST });
    try {
      const response = await ApiList.editNotification(id, request);
      if (response.status == 200) {
        navigate("/notification");
        // navigate(`/subject`);
        dispatch({
          type: types.UPDATE_NOTIFICATION_SUCCESS,
          payload: response,
        });
      } else {
        dispatch({
          type: types.UPDATE_NOTIFICATION_ERROR,
          payload: response.message,
        });
        AlertHelper.warning(response.message);
      }
    } catch (error) {
      dispatch({
        type: types.UPDATE_NOTIFICATION_ERROR,
        payload: error.message || error,
      });
      AlertHelper.warning(error.message || error);
    } finally {
      dispatch(apiLoadingStop());
    }
  };
};
