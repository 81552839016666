export const RESET_STORE_PRODUCT = "RESET_STORE_PRODUCT";

export const Lesson_LIST_SUCCESS = "Lesson_LIST_SUCCESS";
export const Lesson_LIST_REFRESH_REQUEST = "Lesson_LIST_REFRESH_REQUEST";
export const Lesson_LIST_REQUEST = "Lesson_LIST_REQUEST";
export const Lesson_LIST_ERROR = "Lesson_LIST_ERROR";

export const CREATE_LESSON_REQUEST = "CREATE_LESSON_REQUEST";
export const CREATE_LESSON_SUCCESS = "CREATE_LESSON_SUCCESS";
export const CREATE_LESSON_ERROR = "CREATE_LESSON_ERROR";

export const UPDATE_LESSON_SETTING_REQUEST = "UPDATE_LESSON_SETTING_REQUEST";
export const UPDATE_LESSON_SETTING_SUCCESS = "UPDATE_LESSON_SETTING_SUCCESS";
export const UPDATE_LESSON_SETTING_ERROR = "UPDATE_LESSON_SETTING_ERROR";

export const LESSON_ASSIGN_TO_COURSE_REQUEST = "LESSON_ASSIGN_TO_COURSE_REQUEST";
export const LESSON_ASSIGN_TO_COURSE_SUCCESS = "LESSON_ASSIGN_TO_COURSE_SUCCESS";
export const LESSON_ASSIGN_TO_COURSE_ERROR = "LESSON_ASSIGN_TO_COURSE_ERROR";

export const LESSON_EDIT_REQUEST = "LESSON_EDIT_REQUEST";
export const LESSON_EDIT_SUCCESS = "LESSON_EDIT_SUCCESS";
export const LESSON_EDIT_ERROR = "LESSON_EDIT_ERROR";

export const LESSON_DETAILS_REQUEST = "LESSON_DETAILS_REQUEST";
export const LESSON_DETAILS_SUCCESS = "LESSON_DETAILS_SUCCESS";
export const LESSON_DETAILS_ERROR = "LESSON_DETAILS_ERROR";

export const CREATE_LIVE_CLASS_REQUEST = "CREATE_LIVE_CLASS_REQUEST";
export const CREATE_LIVE_CLASS_SUCCESS = "CREATE_LIVE_CLASS_SUCCESS";
export const CREATE_LIVE_CLASS_ERROR = "CREATE_LIVE_CLASS_ERROR";


export const LIVE_CLASS_LIST_REQUEST = "LIVE_CLASS_LIST_REQUEST";
export const LIVE_CLASS_LIST_SUCCESS = "LIVE_CLASS_LIST_SUCCESS";
export const LIVE_CLASS_LIST_ERROR = "LIVE_CLASS_LIST_ERROR";


export const UPDATE_LIVE_CLASS_REQUEST = "UPDATE_LIVE_CLASS_REQUEST";
export const UPDATE_LIVE_CLASS_SUCCESS = "UPDATE_LIVE_CLASS_SUCCESS";
export const UPDATE_LIVE_CLASS_ERROR = "UPDATE_LIVE_CLASS_ERROR";


export const DELETE_LIVE_CLASS_REQUEST = "DELETE_LIVE_CLASS_REQUEST";
export const DELETE_LIVE_CLASS_SUCCESS = "DELETE_LIVE_CLASS_SUCCESS";
export const DELETE_LIVE_CLASS_ERROR = "DELETE_LIVE_CLASS_ERROR";

export const GET_LIVE_CLASS_TOKEN_REQUEST = "GET_LIVE_CLASS_TOKEN_REQUEST";
export const GET_LIVE_CLASS_TOKEN_SUCCESS = "GET_LIVE_CLASS_TOKEN_SUCCESS";
export const GET_LIVE_CLASS_TOKEN_ERROR = "GET_LIVE_CLASS_TOKEN_ERROR";

export const EDIT_LIVE_CLASS_REQUEST = "EDIT_LIVE_CLASS_REQUEST";
export const EDIT_LIVE_CLASS_SUCCESS = "EDIT_LIVE_CLASS_REQUEST";
export const EDIT_LIVE_CLASS_ERROR = "EDIT_LIVE_CLASS_REQUEST";



