import * as types from "./actionTypes";
import ApiList from "../../webservice/ApiList";
import { apiLoadingStart, apiLoadingStop } from "../global";
import { AlertHelper } from "../../Constants/AlertHelper";
import toast from "react-hot-toast";
export const resetStore = () => ({
  type: types.RESET_STORE_PRODUCT,
});

export const getSubjectListAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());

    const requestType = request?.isRefresh
      ? types.SUBJECT_LIST_REFRESH_REQUEST
      : types.SUBJECT_LIST_REQUEST;

    dispatch({ type: requestType });

    try {
      const response = await ApiList.getSubjectList(request);
      if (response) {
        dispatch({ type: types.SUBJECT_LIST_SUCCESS, payload: response });
      } else {
        dispatch({ type: types.SUBJECT_LIST_ERROR, payload: response.message });
        AlertHelper.warning(response.message);
      }
    } catch (error) {
      dispatch({
        type: types.SUBJECT_LIST_ERROR,
        payload: error.message || error,
      });
      AlertHelper.warning(error.message || error);
    } finally {
      dispatch(apiLoadingStop());
    }
  };
};

export const createSubjectAction = (request, navigate) => {
  return async (dispatch) => {
    try {
      dispatch(apiLoadingStart());
      dispatch({ type: types.CREATE_SUBJECT_REQUEST });

      const response = await ApiList.createSubject(request);
      if (response) {
        navigate("/subject");
        toast.success(response?.message)
        dispatch({ type: types.CREATE_SUBJECT_SUCCESS, payload: response });
      } else {
        dispatch({ type: types.CREATE_SUBJECT_ERROR, payload: response });
        setTimeout(() => AlertHelper.warning(response), 100);
      }
    } catch (error) {
      dispatch({ type: types.CREATE_SUBJECT_ERROR, payload: error });
      setTimeout(() => AlertHelper.warning(error), 100);
    } finally {
      dispatch(apiLoadingStop());
    }
  };
};

export const getSubjectLessonListAction = (id, navigate) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());

    const requestType = id?.isRefresh
      ? types.SUBJECT_LESSON_LIST_REFRESH_REQUEST
      : types.SUBJECT_LESSON_LIST_REQUEST;

    dispatch({ type: requestType });

    try {
      const response = await ApiList.getSubjectLesson(id);
      if (response) {
        navigate(`/subject/${id}`);
        dispatch({
          type: types.SUBJECT_LESSON_LIST_SUCCESS,
          payload: response,
        });
      } else {
        dispatch({
          type: types.SUBJECT_LESSON_LIST_ERROR,
          payload: response.message,
        });
        AlertHelper.warning(response.message);
      }
    } catch (error) {
      dispatch({
        type: types.SUBJECT_LESSON_LIST_ERROR,
        payload: error.message || error,
      });
      AlertHelper.warning(error.message || error);
    } finally {
      dispatch(apiLoadingStop());
    }
  };
};
export const subjectEditAction = ({ id, request }) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    dispatch({ type: types.SUBJECT_EDIT_REQUEST });
    try {
      const response = await ApiList.updateSubject(id, request);
      if (response) {
        // navigate(`/subject`);
        dispatch({
          type: types.SUBJECT_EDIT_SUCCESS,
          payload: response,
        });
      } else {
        dispatch({
          type: types.SUBJECT_EDIT_ERROR,
          payload: response.message,
        });
        AlertHelper.warning(response.message);
      }
    } catch (error) {
      dispatch({
        type: types.SUBJECT_EDIT_ERROR,
        payload: error.message || error,
      });
      AlertHelper.warning(error.message || error);
    } finally {
      dispatch(apiLoadingStop());
    }
  };
};
export const deleteSubjectAction = (id) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    dispatch({ type: types.DELETE_SUBJECT_REQUEST });
    try {
      const response = await ApiList.deleteSubject(id);
      if (response) {
        dispatch({
          type: types.DELETE_SUBJECT_SUCCESS,
          payload: response,
        });
        window.location.reload();
      } else {
        dispatch({
          type: types.DELETE_SUBJECT_ERROR,
          payload: response.message,
        });
        AlertHelper.warning(response.message);
      }
    } catch (error) {
      dispatch({
        type: types.DELETE_SUBJECT_ERROR,
        payload: error.message || error,
      });
      AlertHelper.warning(error.message || error);
    } finally {
      dispatch(apiLoadingStop());
    }
  };
};
